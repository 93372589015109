

.Container{


    color: var(--color-text);
    margin-left: 10%;
    margin-right: 10%;
    z-index: 10;
    display: flex;
    justify-content: center;
    flex-direction: column;
   

}



.teams{
    margin-top: 40px;
    /* display: flex; */
    /* flex-direction: row;  */
    /* flex-wrap: wrap;  */
     justify-content: space-between;
    /* gap: 20px; */
    
    width: 100%;
    /* padding-left: 30px; */
    /* padding: 10px 70px; */
    
    

}





.head{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;

  
}


.head_about{
  display: inline-block;
  font-size: 15px;
  letter-spacing: .1em;
  color: var(--primaryColor);
  background: linear-gradient(90.99deg,#FDCE39 .27%,#FD8963 30%,#ED5174 98.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 600;
  text-transform: uppercase;

}

.head_title{
  margin-top: 0;
  color: white;
  font-size: 40px;
  font-weight: 800;
  font-family: var(--headingFontFamily);
}

/* ----------------card item ------------------ */



@media (max-width:425px) {
 

}

@media (min-width:426px)and(max-width: 767px) {
  
}

/*---------------- Styles for screens with a minimum width of 768px and maximum width of 1023px (tablets) --------------------*/
@media (min-width: 768px) and (max-width: 1024px) 

  {
    
   

  
  }
