.roadmap-area {
  position: relative;
  z-index: 1;
  /* padding-top: 120px; */
}
.roadmap-container {
  max-width: 1920px;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
 
}
.section-title span {
  margin-bottom: 12px;
  display: inline-block;
  letter-spacing: 0.1em;
  color: var(--primaryColor);
  background: linear-gradient(
    90.99deg,
    #fdce39 0.27%,
    #fd8963 30%,
    #ed5174 98.69%
  );
  
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: var(--headingFontFamily);
  font-weight: 600;
}
.section-title h2 {
  font-size: 55px;
  margin-bottom: 0;
  /* font-weight: 600; */
  font-family: var(--headingFontFamily);
}
.container-roadmap {
  display: flex;
  flex-direction: row;
  
  overflow: hidden;
}
.roadmap-item {
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(134px);
  backdrop-filter: blur(134px);
  border-radius: 30px;
  margin-bottom: 30px;
  padding: 25px;
  margin: 20px;
  /* min-width: 25%; */
  min-width: 90%;
}
.roadmap-item .date {
  background: linear-gradient(90.99deg,#b7fd20 .27%,#d7ef59 98.69%);
  /* background: linear-gradient(90deg, rgba(177,253,31,1) 0%, rgba(222,253,29,1) 50%, rgba(252,201,69,1) 100%); */
  -webkit-backdrop-filter: blur(134px);
  backdrop-filter: blur(134px);
  display: inline-block;
  margin-bottom: 20px;
  border-radius: 25px;
  padding: 20px 30px;
  text-align: center;
  font-family: var(--headingFontFamily);
  color: var(--mp-black);
  font-weight: bold;
}
/* .roadmap-item .date */
.roadmap-item ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  /* font-weight: 600; */
  /* width: 600; */
  margin-top: 0;
  margin-bottom: 1rem;
  /* padding-left: 2rem; */
}

.roadmap-item ul li {
  font-family: var(--headingFontFamily);
  margin-bottom: 15px;
  position: relative;
  padding-left: 18px;
  /* font-weight: 600; */

  position: relative;
  padding-left: 1.5em; /* Adjust the padding as needed */
}
.roadmap-item ul li::before {
  content: "\2022"; /* Unicode character for a bullet point (•) */
  position: absolute;
  left: 0;

  /*text-align: center;
    /* border: 1px solid #fff;  */
  border-radius: 50%;

  left: 0;
  top: 7px;
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
  background-color: var(--whiteColor);
}
.roadmap-slides {
  margin: 60px;

}
/* .light-img {
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.6;
  position: absolute; */
.light-img {
  top: 8rem;
  left: 1rem;
  height: 60%;
  z-index: -1;
  opacity: 1;
  position: absolute;
}
.light-img-2 {
  bottom: 0;
  right: 5rem;
  height: 60%;
  z-index: -1;
  opacity: 1;
  position: absolute;
}
.owl-nav{
    left: 0;
    right: 0;
    bottom: 17px;
    max-width: 1350px;
    
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;

    -webkit-tap-highlight-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    
}
.owl-prev{
    padding-left: 18px;
    left: 27px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background: linear-gradient(90.99deg,#b7fd20 .27%,#d7ef59 98.69%) ;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 800;
       display: flex;
      flex-direction: row;
}
.icon{
    transform: translateY(-50%);
    position: absolute;
    font-size: 25px;
    top: 50%;
}
.owl-next{
    padding-right: 18px;
    left: auto;
    /* right: auto; */
    float: right;
    right: 0;
    /* margin-bottom:40px ; */
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background: linear-gradient(90.99deg,#b7fd20 .27%,#d7ef59 98.69%) ;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 800;
       display: flex;
      flex-direction: row;
}
.owl-next P{
  /* margin-bottom: 8px; */
}
.owl-dots{
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    height: 0.6rem;
    overflow: hidden;
    max-width: 1140px;
    /* max-width: 1000px; */
    border-radius: 30px;
    background: rgba(255,255,255,.08);
    /* background: #FDCE39; */
    margin-left: auto;
    margin-right: auto;
 
    text-align: center;
    /* -webkit-tap-highlight-color: transparent; */
}

.owl-dot{
    flex: 1;
    display: inline-block;
    zoom: 1;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    /* width: 285.01px; */
    width: 185px;
}
.dot-item{
    /* width: 33.30%; */
    max-width: 50px;
    /* padding: 10px; */
    height: 10px;
    margin: 0px 7px;
    background: #D6D6D6;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity .5s ease;
    border-radius: 30px;
    /* width: 100%; */
    background: unset;
    transition: var(--transition);
    display: flex;
    align-items: center;
    /* margin-top: 10px; */
    
}
.new-icon{
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 5px;
    /* background: ; */
    -webkit-background-clip:text;
    color:linear-gradient(90.99deg,#b7fd20 .27%,#d7ef59 98.69%) ;
    -webkit-text-fill-color: transparent;
}

.dot-item.active{
    max-width: 100%;
    background:linear-gradient(90.99deg,#b7fd20 .27%,#d7ef59 98.69%) ; /* Set the background color for the active dot */
    color: #fff;
    
  }

/* ----------------------------------Responsive for mobile/Tablets Screen -------------------------------------------- */
@media (max-width:425px) {
  .light-img , .light-img-2{
    visibility: hidden;

  }
  .roadmap-slides{
    /* width: 100%; */
    margin: 30px 0px;
  }
  
  .roadmap-item {
    /* display: flex; */
    border-radius: 30px;
    margin-bottom: 30px;
    padding: 15px;
    margin: 0px;
    /* justify-content: center; */
    /* margin: auto; */
  }
  .roadmap-item .date {
  
    margin-bottom: 20px;
    border-radius: 25px;
    padding: 15px 20px;
  
    font-weight: bold;
  }
  .roadmap-item ul li {
    font-family: var(--headingFontFamily);
    margin-bottom: 10px;
    /* position: relative; */
    padding-left: 13px;
    /* font-weight: 600; */
  
    /* position: relative; */
    padding-left: 1em; /* Adjust the padding as needed */
  }
  .owl-dots{
    display: none;
    /* justify-content: space-evenly; */
    

  }
  .owl-nav{
     width: 250px;
    justify-content: space-evenly;
    
  }

}

@media (min-width:426px)and(max-width: 767px) {
  .roadmap-slides{
    /* width: 100%; */
    margin: 20px;
  }
  .container-roadmap {
   
   
    justify-content: space-evenly;
    margin: 70px;
    
  }
  .roadmap-item .date {
  
    margin-bottom: 20px;
    border-radius: 25px;
    padding: 15px 25px;
  
    font-weight: bold;
  }
  .roadmap-item {
    
    border-radius: 30px;
    margin-bottom: 30px;
    padding: 15px;
    margin: 10px;
    /* margin: 15px; */
  }
  
  .owl-dots{
    display: none;
    /* justify-content: space-evenly; */
    

  }
  .owl-nav{
     width: 250px;
    justify-content: space-evenly;
    
  }
}

/*---------------- Styles for screens with a minimum width of 768px and maximum width of 1023px (tablets) --------------------*/
@media (min-width: 768px) and (max-width: 1024px) 

  {
    .roadmap-slides{
      /* width: 100%; */
      margin: 40px;
    }
    .owl-nav{
     
      max-width:900px;
     
      
    }
    .owl-next ,.owl-prev{
      padding-bottom: 5px;
      /* display: flex;
      flex-direction: row; */
    }
    .owl-next p{
      margin: 0px 5px;
    }
    .roadmap-item {
    
      border-radius: 30px;
      margin-bottom: 30px;
      padding: 25px;
      margin: 30px;
    }
   

  
  }

/*-------------------------------- Additional styles for larger screens------------------------------ */
@media (min-width: 1281px) {
  
}
