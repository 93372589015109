.working-layout {
  /* max-width: 1200px; */
  overflow-x: hidden;
  /* margin: auto auto; */
  z-index: 5;
}

@media (max-width: 768px) {
  .working-layout {
    max-width : 100%;
  }
}

.container {
  /* background-color: rgb(47, 29, 91); */
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  width: 100%;
  position: relative;
  margin: 0 auto;
}

.head_title {
  margin-top: 0;
  color: white;
  font-size: 45px;
  font-weight: 800;
}

.working-overlay-img {
  max-width: 40%;
  opacity: 0.7;
  z-index: -1;
  height: auto;
  width: auto;
  position: absolute;
  left: 25%;
  top: 0%;
  transition: opacity 0.3s ease-in-out;
}

@keyframes off-on {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.listItems {
  display: flex;
  flex-direction: row;
  margin-top: 20px auto;
  gap: 50px;
  list-style: none;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  cursor: pointer;
}

.listItem {
  background: var(--mp-grayDark);
  color: white;
  font-size: 18px;
  font-family: sans-serif;
  border-radius: 10px;
  padding: 22px 70px;
  font-weight: 600;
  letter-spacing: 0.2px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.select-focus {
  background: linear-gradient(to right, var(--mp-grayDark), var(--mp-black)) padding-box,
    var(--mp-primaryGrad) border-box;
  border: 4px solid transparent;
  animation: gradientAnimation 0.5s ease-in-out infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.working-eff {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  z-index: 20;
  position: relative;
}

.eff-a {
  position: absolute;
  margin-top: 20px;
  height: 3px;
  width: 70%;
  margin: auto;
  bottom: -20px;
  background: var(--mp-primaryColor);
  border-radius: 25px;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.5s ease;
}

.working-eff:hover .eff-a {
  transform: scaleX(1);
  transform-origin: left;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  margin: 0 10%;
  max-height: 75%;
  max-width: 95%;
  z-index: 3;
  overflow: hidden;
  border-radius: 15px;
}

.imageContainer img {
  border-radius: 65px;
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
  scale: 1.3;
}

.image_description {
  display: grid;
  grid-template-columns: 400px auto;
  justify-content: center;
  margin-top: 25px;
  padding: 0px 12px;
  overflow: hidden;
  margin-bottom: 40px;
}

.image_title {
  padding: 0 30px;
  margin: 0 0 12px;
  color: white;
  font-size: 30px;
  font-weight: bold;
  font-family: var(--headingFontFamily);
  text-align: left;
  text-transform: capitalize;
}

.image_about {
  color: white;
  font-size: 18px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image_about p {
  font-family: var(--fontFamily);
  color: var(--paragraphColor);
  font-size: var(--fontSize);
  line-height: 1.8;
  font-weight: 300;
  text-align: center;
}

/* Media Query for screens up to 600px */
@media (max-width: 600px) {
  .image_description {
    grid-template-columns: 1fr;
    justify-content: center;
    padding: 0px 0px;
  }

  .image_title {
    padding: 0 10px;
    margin-bottom: 10px;
    font-size: 24px;
    text-align: center;
  }

  .image_about {
    font-size: 14px;
  }

  .image_about p {
    font-size: 30px;
    padding: 0 17px;
  }
}


.overlay-rot-img-b {
  width: auto;
  height: auto;
  position: absolute;
  top: 6%;
  left: 17%;
  z-index: 0;
  animation: rotation 30s infinite linear;
}

.overlay-rot-img-c {
  width: auto;
  height: auto;
  position: absolute;
  top: 30%;
  right: 7%;
  z-index: 0;
  animation: rotation 30s infinite linear;
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(359deg);
  }
}

@media (max-width: 768px) {
  .overlay-rot-img-b {
    margin: 0% 0%;
    top: 0;
    left: 7%;
  }

  .overlay-rot-img-c {
    display: none;
  }

  .listItems {
    margin-top: 20px auto;
    gap: 20px;
  }

  .listItem {
    color: white;
    font-size: 16px;
    background: none;
    border-radius: 10px;
    padding: 18px 50px;
    font-weight: 600;
    letter-spacing: 0.2px;
  }

  .select-focus {
    background: linear-gradient(to right, var(--mp-grayDark), var(--mp-black)) padding-box,
      var(--mp-primaryGrad) border-box;
    border: 4px solid transparent;
    animation: gradientAnimation 0.5s ease-in-out infinite;
  }
}
